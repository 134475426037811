import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const fragment = graphql`
  fragment MediaItem on WP_MediaItem {
    srcSet
    sourceUrl
    altText
    id
    sourceUrlSharp {
      childrenImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    mimeType
  }
`;

const Image = ({ image, className, imgStyle }) => {
  return (
    <GatsbyImage
      className={`block w-full ${className}`}
      image={
        image && image.sourceUrlSharp.childrenImageSharp[0].gatsbyImageData
      }
      alt={image && image.altText}
      imgStyle={imgStyle}
    />
  );
};

export default Image;
